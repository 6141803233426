import "./social-share.scss"

export default function SocialShare() {

    const buttonShare = document.getElementById("socialDropDown");

    if (buttonShare) {    
        function openDropdown() {
            buttonShare!.setAttribute(
                'aria-expanded',
                buttonShare!.getAttribute('aria-expanded') === 'true'
                    ? 'false'
                    : 'true'
            );
            const boxDropdown = document.getElementById("box-dropdown") as HTMLDivElement;
            boxDropdown!.classList.toggle("hide");
        }

        buttonShare!.addEventListener("click", openDropdown);

        const facebookBtn: HTMLElement | null = document.querySelector(".facebook-btn");
        const twitterBtn: HTMLElement | null = document.querySelector(".twitter-btn");
        const pinterestBtn: HTMLElement | null = document.querySelector(".pinterest-btn");
        const linkedinBtn: HTMLElement | null = document.querySelector(".linkedin-btn");
        const whatsappBtn: HTMLElement | null = document.querySelector(".whatsapp-btn");

        function initSocialShare(): void {

            let postUrl: string = encodeURI(document.location.href);

            if (facebookBtn) {
                facebookBtn.setAttribute(
                    "href",
                    `https://www.facebook.com/sharer.php?u=${postUrl}`
                );
                facebookBtn?.addEventListener("click", function(event){
                    event.preventDefault();
                    const linkPage = facebookBtn!.getAttribute("href");
                    const windowFeatures = "left=10,top=10,width=400,height=400";
                    window.open(linkPage!, "facebookWindow", windowFeatures);                
                });
            }

            if (twitterBtn) {
                twitterBtn.setAttribute(
                    "href",
                    `https://twitter.com/share?url=${postUrl}`
                );
                twitterBtn?.addEventListener('click', function(event){
                    event.preventDefault();
                    const linkPage = twitterBtn!.getAttribute("href");
                    const windowFeatures = "left=10,top=10,width=400,height=400";
                    window.open(linkPage!, "twitterWindow", windowFeatures);
                });
            }

            if (pinterestBtn) {
                pinterestBtn.setAttribute(
                    "href",
                    `https://pinterest.com/pin/create/bookmarklet/?url=${postUrl}`
                );
                pinterestBtn?.addEventListener('click', function(event){
                    event.preventDefault();
                    const linkPage = pinterestBtn!.getAttribute("href");
                    const windowFeatures = "left=10,top=10,width=400,height=400";
                    window.open(linkPage!, "pinterestWindow", windowFeatures);
                });
            }

            if (linkedinBtn) {
                linkedinBtn.setAttribute(
                    "href",
                    `https://www.linkedin.com/shareArticle?url=${postUrl}`
                );
                linkedinBtn?.addEventListener('click', function(event){
                    event.preventDefault();
                    const linkPage = linkedinBtn!.getAttribute("href");
                    const windowFeatures = "left=10,top=10,width=400,height=400";
                    window.open(linkPage!, "linkedinWindow", windowFeatures);
                });
            }

            if (whatsappBtn) {
                whatsappBtn.setAttribute(
                    "href",
                    `whatsapp://send?text=${postUrl}`
                );
                whatsappBtn?.addEventListener('click', function(event){
                    event.preventDefault();
                    const linkPage = whatsappBtn!.getAttribute("href");
                    window.open(linkPage!,"_self");
                });
            }
        }

        initSocialShare();
    }
}