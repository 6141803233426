import "./product-detail.scss"

export default function ProductDetail() {
    const productThumb = document.querySelectorAll('.product-thumbs .product-thumb a')
    const zoomedImg = document.getElementById('zoomed-img') as HTMLImageElement;
    if (productThumb) {
        productThumb.forEach((item:any) => {
            item.addEventListener("click", function(target:any){
                target.preventDefault();
                zoomedImg.setAttribute("src", this.getAttribute("href"));
                zoomedImg.animate([{ opacity: '0'}, { opacity: '1'}], { duration: 500 })
            })
        })
    }
}
