import "./card.scss"

export default function Card() {
    const card = document.querySelectorAll('.card.article');

    if (card) {
        card.forEach(item => {
            const dateCreationAttr = item.getAttribute('data-creation')
            const dateEvaluationAttr = item.getAttribute('data-evaluation')
            const badgeNew = item.querySelector('.badge-new-wrapper') as HTMLHtmlElement
            const badgeNewMobile = item.querySelector('.badge-new-wrapper-mob') as HTMLHtmlElement

            if ((item.hasAttribute("data-creation") && item.hasAttribute("data-evaluation")) &&
                ((dateEvaluationAttr != '') &&
                    (dateCreationAttr != '')) && ((dateEvaluationAttr != 'undefined') && (dateCreationAttr != 'undefined'))) {
                let dateNow = new Date()
                dateNow.setHours(0, 0, 0, 0)

                let dateCreation = new Date(String(dateCreationAttr))
                dateCreation.setHours(0, 0, 0, 0)

                let dateEvaluation = new Date(String(dateEvaluationAttr))
                dateEvaluation.setHours(0, 0, 0, 0)

                if (dateCreation > dateNow || dateEvaluation <= dateNow) {
                    if (badgeNew) {
                        badgeNew.classList.add("d-none")
                    }
                    if (badgeNewMobile) {
                        badgeNewMobile.classList.add("d-none")
                    }
                }
            } else {
                if (badgeNew) {
                    badgeNew.classList.add("d-none")
                }
                if (badgeNewMobile) {
                    badgeNewMobile.classList.add("d-none")
                }
            }
        })
    }

}