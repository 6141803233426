import "./filters-category.scss";
import { CHILD_TYPE, CONTAINER_TYPE, FILTER_TYPE } from "../../ts/enums";
import { getTargets } from "../../ts/utilities";
import customSelects from "custom-select";

import type { SearchResultType } from "../../ts/types";

export default function FiltersCategory() {

    const $targets = getTargets("filters-category");
    const cardTemplate = document.querySelector('#card-template') as HTMLTemplateElement;
    const prevItemTemplate = document.querySelector('#result-item-template') as HTMLTemplateElement;
    const urlParams = new URLSearchParams(window.location.search);
    const paramCategory = cleanInput(urlParams.get("filter") || "");
    const templateBrands = document.querySelector('[data-brand-info]') as HTMLTemplateElement;
    const templateCategories = document.querySelector('[data-categories-info]') as HTMLTemplateElement;

    if ($targets.length > 0) {
        const $filtersWrapper = $targets[0] as HTMLElement;
        const $selects = $filtersWrapper.querySelectorAll(".filters") as NodeListOf<HTMLDivElement>;
        const $buttonLoad = document.querySelector('[data-load-more]') as HTMLDivElement;
        const $searchIcon = document.querySelector('.filter-search-button') as HTMLDivElement;
        const $searchInput = document.querySelector('.filter-search-input') as HTMLInputElement;
        const $autoCompleteWrapper = document.querySelector('.autocompletion-wrapper') as HTMLDivElement;
        const $articleItemsContainer = document.querySelector(`.${CONTAINER_TYPE.ARTICLE_CONTAINER}`) as HTMLDivElement;
        const $brandsItemsContainer = document.querySelector(`.${CONTAINER_TYPE.BRAND_CONTAINER}`) as HTMLDivElement;
        const $productItemsContainer = document.querySelector(`.${CONTAINER_TYPE.PRODUCT_CONTAINER}`) as HTMLDivElement;
        const $recipeItemsContainer = document.querySelector(`.${CONTAINER_TYPE.RECIPE_CONTAINER}`) as HTMLDivElement;
        const $cardsContainer = $articleItemsContainer || $brandsItemsContainer || $productItemsContainer || $recipeItemsContainer;

        if ($selects.length > 0 && $cardsContainer) {
            const containerType = $cardsContainer.getAttribute("class");
            const childClass = (containerType && containerType === `swiper-wrapper ${CONTAINER_TYPE.BRAND_CONTAINER}`) ? `.${CHILD_TYPE.BRAND_CHILD}` : `.card.${CHILD_TYPE.ARTICLE_CHILD}`;
            const $allCards = $cardsContainer.querySelectorAll(childClass) as NodeListOf<HTMLDivElement>;
            const country: string = $cardsContainer.getAttribute('data-country') ?? "";
            const language: string = $cardsContainer.getAttribute('data-language') ?? "";
            const pageType: string = $cardsContainer.getAttribute('data-page-type') ?? "";
            let maxItemsVisible: number | undefined = Number($cardsContainer?.getAttribute('data-search-number-of-items'));
            let increment: number = maxItemsVisible;
            let isSearchActive: boolean = false;
            let offsetSearch: number = 0;
            let searchKey: string = "";
            let searchType: string = "filters";
            let valueSelectCategory: string = "";
            let valueSelectBrand: string = "";

            function bindUi() {
                let timeout = setTimeout(function () { }, 0);

                $searchInput?.addEventListener("blur", () => {
                    setTimeout(function () {
                        closeResultsPreview({ resultsContainer: $autoCompleteWrapper });
                    }, 400)                    
                });
                $searchInput?.addEventListener("keyup", (e) => {
                    clearTimeout(timeout);
                    if (e.key !== "Enter") {
                        timeout = setTimeout(function () {
                            resetSelect({ selects: $selects });

                            if ($searchInput.value.length > 3 || $searchInput.value.length === 0) {
                                fetchSearchResultsPreview({
                                    container: $autoCompleteWrapper,
                                    country: country,
                                    language: language,
                                    pageType: pageType,
                                    searchKey: $searchInput.value,
                                    searchKeyLenght: $searchInput.value.length,
                                    searchType: "search-filter"
                                })
                            }
                        }, 1000)
                    } else {
                        if ($searchInput.value.length > 3) {
                            isSearchActive = true;
                            searchType = "search-filter";
                            searchKey = $searchInput.value;
                            valueSelectBrand = "";
                            valueSelectCategory = "";
                            offsetSearch = 0;

                            fetchSearchResults({
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                category: valueSelectCategory,
                                country: country,
                                isLoadMore: false,
                                language: language,
                                maxItemsVisible: maxItemsVisible,
                                offset: offsetSearch,
                                pageType: pageType,
                                searchKey: searchKey,
                                searchType: searchType
                            })
                        }
                        else {
                            $searchInput.value = "";
                            isSearchActive = false;
                            offsetSearch = 0;
                            searchType = "filters";
                            valueSelectBrand = "";
                            valueSelectCategory = "";
                            resetAllFilters({
                                allCards: $allCards,
                                buttonLoad: $buttonLoad,
                                cardsContainer: $cardsContainer,
                                maxItemsVisible: maxItemsVisible,
                                selects: $selects,
                            });
                        }
                        closeResultsPreview({ resultsContainer: $autoCompleteWrapper });

                    }

                });
                $searchIcon?.addEventListener("click", () => {
                    offsetSearch = 0;
                    closeResultsPreview({ resultsContainer: $autoCompleteWrapper });
                    if ($searchInput.value.length > 3) {
                        isSearchActive = true;
                        searchType = "search-filter";
                        searchKey = $searchInput.value;
                        valueSelectBrand = "";
                        valueSelectCategory = "";
                        fetchSearchResults({
                            buttonLoad: $buttonLoad,
                            cardContainer: $cardsContainer,
                            category: valueSelectCategory,
                            country: country,
                            isLoadMore: false,
                            language: language,
                            maxItemsVisible: maxItemsVisible,
                            offset: offsetSearch,
                            pageType: pageType,
                            searchKey: searchKey,
                            searchType: searchType
                        })
                    }
                    else {
                        $searchInput.value = "";
                        isSearchActive = false;
                        searchType = "filters";
                        valueSelectBrand = "";
                        valueSelectCategory = "";
                        resetAllFilters({
                            allCards: $allCards,
                            buttonLoad: $buttonLoad,
                            cardsContainer: $cardsContainer,
                            maxItemsVisible: maxItemsVisible,
                            selects: $selects
                        });
                    }
                })
                $buttonLoad?.addEventListener("click", () => {
                    if (maxItemsVisible) {
                        if (!isSearchActive) {
                            increment = increment + maxItemsVisible;
                            filter({
                                allCards: $allCards,
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                childClass: childClass,
                                maxItemsVisible: increment,
                                valueSelectCategory: valueSelectCategory,
                                valueSelectBrand: valueSelectBrand
                            });
                        }
                        else {
                            offsetSearch = offsetSearch + maxItemsVisible;
                            fetchSearchResults({
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                category: valueSelectCategory,
                                country: country,
                                isLoadMore: true,
                                language: language,
                                maxItemsVisible: maxItemsVisible,
                                offset: offsetSearch,
                                pageType: pageType,
                                searchKey: searchKey,
                                searchType: searchType
                            });
                        }

                    }


                });

                $selects.forEach(($select) => {
                    const select = $select.querySelector("select") as HTMLSelectElement;
                    const selectType = select.getAttribute("id") as string;


                    if (selectType === FILTER_TYPE.CATEGORY && paramCategory) {
                        const $selectedOption = [].slice.call(select.querySelectorAll('option')).find((option: HTMLOptionElement) => cleanInput(option.innerHTML) === paramCategory);
                        if ($selectedOption) {
                            valueSelectCategory = ($selectedOption as HTMLSelectElement).value;
                            select.value = ($selectedOption as HTMLSelectElement).value;
                            filter({
                                allCards: $allCards,
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                childClass: childClass,
                                maxItemsVisible: maxItemsVisible,
                                valueSelectCategory: valueSelectCategory,
                                valueSelectBrand: valueSelectBrand
                            })
                        }
                    }

                    const $customSelect = customSelects(select)[0].select as HTMLSelectElement;

                    $customSelect.addEventListener("change", () => {
                        if (maxItemsVisible) {
                            increment = maxItemsVisible;
                        }
                        if (selectType === FILTER_TYPE.BRAND) {
                            valueSelectBrand = $customSelect.value;
                        }
                        else {
                            valueSelectCategory = $customSelect.value;
                        }
                        if (!isSearchActive) {
                            filter({
                                allCards: $allCards,
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                childClass: childClass,
                                maxItemsVisible: maxItemsVisible,
                                valueSelectCategory: valueSelectCategory,
                                valueSelectBrand: valueSelectBrand
                            })
                        }
                        else {
                            offsetSearch = 0;

                            fetchSearchResults({
                                buttonLoad: $buttonLoad,
                                cardContainer: $cardsContainer,
                                category: valueSelectCategory,
                                country: country,
                                isLoadMore: false,
                                language: language,
                                maxItemsVisible: maxItemsVisible,
                                offset: offsetSearch,
                                pageType: pageType,
                                searchKey: searchKey,
                                searchType: searchType
                            })
                        }
                    })

                })
            }

            function init() {
                if (maxItemsVisible && !paramCategory) {
                    setItemsPage({
                        buttonLoad: $buttonLoad,
                        cardContainer: $cardsContainer,
                        childClass: childClass,
                        maxItemsVisible: maxItemsVisible
                    });
                }
                bindUi();
            }
            init();
        }
    }

    function appendResultsSearch({
        buttonLoad,
        cardContainer,
        country,
        isLoadMore,
        results,
        searchOffset
    }: {
        buttonLoad: HTMLDivElement
        cardContainer: HTMLDivElement
        country: string
        isLoadMore: boolean
        results: SearchResultType
        searchOffset: number
    }) {

        if (!isLoadMore) {
            if (results.hits.length > 0) {
                cardContainer.innerHTML = "";
            }
            else {
                cardContainer.innerHTML = "no results found";
            }
        }
        const availableCategories = templateCategories ? JSON.parse(String(templateCategories.content.textContent)) : null;
        const availableBrands = templateBrands ? JSON.parse(String(templateBrands.content.textContent)) : null;
        const isAuthorMode = Boolean(cardContainer.getAttribute('data-author-mode'));
        const totalResultsPresents = results.results + searchOffset;

        if (results.total <= totalResultsPresents) {
            buttonLoad.classList.remove("is-visible")
        }
        else {
            buttonLoad.classList.add("is-visible")
        }

        results?.hits.map((item) => {
            const cardClone = cardTemplate.content.cloneNode(true) as HTMLDivElement;
            const categories = Array.isArray(item['jcr:content']?.categories) ? item['jcr:content']?.categories : [item['jcr:content']?.categories];
            const brands = Array.isArray(item['jcr:content']?.brands) ? item['jcr:content']?.brands : [item['jcr:content']?.brands];
            const categoryJson = availableCategories
                ?.flatMap((category: any) => {
                    if (categories.some((item: any) => item === category.key)) {
                        return category;
                    }
                    else {
                        return []
                    }
                })

            const category = JSON.stringify(categoryJson);
            JSON.stringify(category);

            const brandJson = availableBrands
                ?.flatMap((brand: any) => {
                    if (brands.some((item: any) => item === brand.path)) {
                        return brand;
                    }
                    else {
                        return []
                    }
                })
            const brand = JSON.stringify(brandJson);
            JSON.stringify(brand);
            const cardWrapper = cardClone.querySelector('.card.article') as HTMLDivElement;
            cardWrapper.setAttribute('data-category', category);

            ////set badge new
            const dateFrom = String(item['jcr:content']['data-creation']);
            const dateEvaluation = String(item['jcr:content']['data-evaluation']);
            if (dateFrom && dateEvaluation && item['jcr:content']['new-label']) {
                const badgeNewVisible: boolean = new Date() > new Date(dateFrom) && new Date() <= new Date(dateEvaluation);
                cardWrapper.setAttribute('data-creation', dateFrom);
                cardWrapper.setAttribute('data-evaluation', dateEvaluation);
                if (badgeNewVisible) {
                    const badgeNewWrapper = cardClone.querySelectorAll('.badge-new-wrapper') as NodeListOf<HTMLDivElement>;
                    const badgeNewWrapperMob = cardClone.querySelectorAll('.badge-new-wrapper-mob') as NodeListOf<HTMLDivElement>;
                    if (badgeNewWrapper.length >= 0) {
                        badgeNewWrapper.forEach((el) => {
                            const badge = document.createElement('div');
                            badge.classList.add('badge-new');
                            badge.textContent = item['jcr:content']['new-label'] ? item['jcr:content']['new-label'] : '';
                            el.appendChild(badge);
                        });
                    }
                    if (badgeNewWrapperMob.length >= 0) {
                        badgeNewWrapperMob.forEach((el) => {
                            const badge = document.createElement('div');
                            badge.classList.add('badge-new');
                            badge.textContent = item['jcr:content']['new-label'] ? item['jcr:content']['new-label'] : '';
                            el.appendChild(badge);
                        });
                    }
                }
            }

            //set data-brand
            cardWrapper.setAttribute('data-brand', brand);

            const image = cardClone.querySelector('.image-container img');

            const position = cardClone.querySelectorAll('.position') as NodeListOf<HTMLDivElement>;

            if (position.length >= 0) {
                position.forEach((el) => {
                    categories.forEach((category: any) => {
                        const categoryText = availableCategories.find((el: any) => el.key === category)?.label?.toString();
                        const badgeColor = availableCategories.find((el: any) => el.key === category)?.color?.toString();

                        if (Boolean(categoryText) && Boolean(badgeColor)) {
                            const badge = document.createElement('div');
                            badge.classList.add('badge');
                            badge.style.backgroundColor = badgeColor;
                            badge.textContent = categoryText;

                            el.appendChild(badge);
                        }
                    });
                });
            }
            const date = cardClone.querySelector('.text-container .date') as HTMLDivElement;
            const title = cardClone.querySelector('.text-container .title') as HTMLDivElement;
            const description = cardClone.querySelector('.text-container .description') as HTMLDivElement;
            const link = cardClone.querySelector('.link') as HTMLElement;
            const linkUrl = Boolean(isAuthorMode) ? item['jcr:path'] + '.html' : '/' + item['jcr:path'].split('/').slice(3) + '.html';

            if (date !== null) {
                const countryDate = country === 'international' ? 'en' : country;
                const dateItem = new Date(String(item['jcr:content']['jcr:created'])).toLocaleDateString(`${countryDate}-${countryDate}`, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
                date.textContent = dateItem ? dateItem : '';
            }


            image?.setAttribute('src', item['jcr:content'].image);
            image?.setAttribute('alt', item['jcr:content'].image);
            title.textContent = item['jcr:content']['jcr:title'] ? item['jcr:content']['jcr:title'] : '';
            description.textContent = item['jcr:content']['jcr:description'] ? item['jcr:content']['jcr:description'] : '';
            link.setAttribute('href', linkUrl.replaceAll(',', '/'));
            cardContainer.appendChild(cardClone);
        });
    }
    function appendSearchResultsPreview({
        results,
        resultsContainer,
        searchKey,
        searchKeyLenght
    }: {
        results: SearchResultType
        resultsContainer: HTMLDivElement
        searchKey: string
        searchKeyLenght: number
    }) {
        if (searchKeyLenght !== 0) {
            resultsContainer.classList.remove("hide");



            if (results.hits.length > 0) {
                resultsContainer.innerHTML = "";
                const isAuthorMode = Boolean(resultsContainer.getAttribute('data-author-mode'));
                results.hits?.forEach(result => {
                    const prevResultCone = prevItemTemplate.content.cloneNode(true) as HTMLDivElement;
                    const description = prevResultCone.querySelector(".description") as HTMLElement;
                    const link = prevResultCone.querySelector("a") as HTMLAnchorElement;
                    const title = prevResultCone.querySelector(".title-recipe") as HTMLElement;

                    const linkUrl = Boolean(isAuthorMode) ? result['jcr:path'] + '.html' : '/' + result['jcr:path'].split('/').slice(3) + '.html';

                    title.textContent = result['jcr:content']['jcr:title'] ? result['jcr:content']['jcr:title'] : '';
                    link.setAttribute("href", linkUrl.replaceAll(',', '/'));

                    //let searchKey = "Lorem ipsum"
                    let resultString: string = result['jcr:content']['jcr:description'] ? result['jcr:content']['jcr:description'] : '';
                    searchKey = searchKey.replace(/\s\s+/g, ' '); //remove double spaces
                    let searchKeyArray = searchKey.split(/\b(\s)/); //split keywords
                    let resultRepleced: string = resultString
                    
                    searchKeyArray.forEach(singleKey => {
                        if ((singleKey != " ") && (singleKey != "")){
                            let regex = RegExp('(' + singleKey + ')', 'gi');
                            resultRepleced = resultRepleced.replace(regex, '<strong class="highlighted">$1</strong>')
                        }
                    });
                    
                    description.innerHTML = resultRepleced;

                    resultsContainer.appendChild(prevResultCone);
                })
            }
            else {
                resultsContainer.innerHTML = "no results found";
            }
        }
        else {
            resultsContainer.innerHTML = "";
            resultsContainer.classList.add("hide");
        }
    }
    function closeResultsPreview(
        {
            resultsContainer
        }: {
            resultsContainer: HTMLDivElement
        }) {
        resultsContainer.innerHTML = "";
        resultsContainer.classList.add("hide");
    }
    function cleanInput(str: string): string {
        return str.replace(/[^\w]/gi, "_").toLowerCase();
    }
    function fetchSearchResultsPreview({
        container,
        country,
        language,
        pageType,
        searchKey,
        searchKeyLenght,
        searchType
    }: {
        container: HTMLDivElement
        country: string
        language: string
        pageType: string
        searchKey: string
        searchKeyLenght: number
        searchType: string
    }) {
        const maxItemsVisiblePreview = 3;
        const offsetPreview = 0;

        const url = String(
            `${window.location.origin}/bin/search.json?type=${searchType}&country=${country}&language=${language}&maxItemVisible=${maxItemsVisiblePreview}&pageType=${pageType}&offset=${offsetPreview}&q=${searchKey}&filterCategory=`
        )
        fetch(url, {
            headers: {
                "content-type": "application/json",
                "method": "GET"
            }
        }).then((response) => {
            return response.json();
        }).then((data: SearchResultType) => {

            appendSearchResultsPreview({
                resultsContainer: container,
                results: data,
                searchKey: searchKey,
                searchKeyLenght: searchKeyLenght
            })
            return data;
        }).catch(error => {
            return `Error: ${error}`
        });

    }
    function fetchSearchResults({
        buttonLoad,
        cardContainer,
        category = "",
        country,
        isLoadMore,
        language,
        offset,
        maxItemsVisible,
        pageType,
        searchKey,
        searchType
    }: {
        buttonLoad: HTMLDivElement
        cardContainer: HTMLDivElement
        category?: string
        country: string
        isLoadMore: boolean
        language: string
        maxItemsVisible?: number
        offset: number
        pageType: string
        searchKey: string
        searchType: string
    }) {
        const url = String(
            `${window.location.origin}/bin/search.json?type=${searchType}&country=${country}&language=${language}&maxItemVisible=${maxItemsVisible ?? ""}&pageType=${pageType}&offset=${offset}&q=${searchKey}&filterCategory=${category !== "all" ? category : ""}`
        )
        fetch(url, {
            headers: {
                "content-type": "application/json",
                "method": "GET"
            }
        }).then((response) => {
            return response.json();
        }).then((data: SearchResultType) => {
            appendResultsSearch({
                buttonLoad: buttonLoad,
                cardContainer: cardContainer,
                country: country,
                isLoadMore: isLoadMore,
                results: data,
                searchOffset: offset
            })
            return data;
        }).catch(error => {
            return `Error: ${error}`
        });
    }
    function filter({
        allCards,
        buttonLoad,
        cardContainer,
        childClass,
        maxItemsVisible,
        valueSelectCategory = "",
        valueSelectBrand = ""
    }: {
        allCards: NodeListOf<HTMLDivElement>,
        buttonLoad: HTMLDivElement,
        cardContainer: HTMLDivElement,
        childClass: string,
        maxItemsVisible?: number,
        valueSelectBrand?: string,
        valueSelectCategory?: string
    }) {
        cardContainer.classList.add('is-filtering');
        setTimeout(() => {
            cardContainer.classList.remove('is-filtering');
            cardContainer.innerHTML = '';

            if (!!valueSelectCategory || !!valueSelectBrand) {

                allCards.forEach((card) => {
                    const categoryAttribute = card.getAttribute('data-category');
                    const category = JSON.parse(String(categoryAttribute) as string);
                    const brandAttribute = card.getAttribute('data-brand');
                    const brand = JSON.parse(String(brandAttribute) as string);
                    const categoryMatch = (category?.some((item: any) => item.key === valueSelectCategory) && category?.length > 0);

                    const brandMatch = (brand?.some((item: any) => item.title === valueSelectBrand) && brand?.length > 0);

                    if ((valueSelectCategory && valueSelectBrand)) {
                        if (valueSelectCategory === "all" && valueSelectBrand === "all") {
                            cardContainer?.appendChild(card);
                        }
                        else if (valueSelectCategory === "all" && brandMatch) {
                            cardContainer?.appendChild(card);
                        }
                        else if (valueSelectBrand === "all" && categoryMatch) {
                            cardContainer?.appendChild(card);
                        }
                        else if (categoryMatch && brandMatch) {
                            cardContainer?.appendChild(card);
                        }

                    }
                    else if (valueSelectCategory && !valueSelectBrand && (categoryMatch || valueSelectCategory === "all")) {
                        cardContainer?.appendChild(card);
                    }
                    else if (!valueSelectCategory && valueSelectBrand && (brandMatch || valueSelectCategory === "all")) {
                        cardContainer?.appendChild(card);
                    }
                    else {
                        card.remove()
                    }

                })
            } else if (!valueSelectCategory && !valueSelectBrand) {
                allCards.forEach((card) => {
                    cardContainer?.appendChild(card);
                })
            }
            if (maxItemsVisible) {
                setItemsPage({
                    buttonLoad: buttonLoad,
                    cardContainer: cardContainer,
                    childClass: childClass,
                    maxItemsVisible: maxItemsVisible
                });
            }
        }, 500)


        setTimeout(() => {
            badgeNewCheck(document.querySelectorAll('.card.article'))
        }, 500)            
    }
    function resetAllFilters({
        allCards,
        buttonLoad,
        cardsContainer,
        maxItemsVisible,
        selects
    }: {

        allCards: NodeListOf<HTMLDivElement>
        buttonLoad: HTMLDivElement
        cardsContainer: HTMLDivElement
        maxItemsVisible?: number
        selects: NodeListOf<HTMLDivElement>
    }) {

        cardsContainer.innerHTML = "";

        if (!maxItemsVisible) {
            allCards.forEach((card) => {
                cardsContainer.appendChild(card);
            });
        }
        else {
            allCards.forEach((card, index) => {
                if (index < maxItemsVisible) {
                    cardsContainer.appendChild(card);
                }
            });

            if (allCards.length > maxItemsVisible) {
                buttonLoad.classList.add("is-visible")
            }
            else {
                buttonLoad.classList.remove("is-visible")
            }
        }
        resetSelect({ selects: selects });
    }
    function resetSelect({ selects }: { selects: NodeListOf<HTMLDivElement> }) {
        selects.forEach(($select) => {
            const selectPanel = $select.querySelector(".custom-select-opener span") as HTMLSpanElement;
            const allOptionLabel = $select.querySelector('[data-value="all"]')?.innerHTML as string;
            selectPanel.innerHTML = "";
            selectPanel.innerHTML = allOptionLabel;
        })
    }
    function setItemsPage({
        buttonLoad,
        cardContainer,
        childClass,
        maxItemsVisible
    }: {
        buttonLoad: HTMLDivElement,
        cardContainer: HTMLDivElement
        childClass: string
        maxItemsVisible: number
    }) {
        const allCardsCurrent = cardContainer.querySelectorAll(childClass);
        const totalItemsCurrent = allCardsCurrent.length;

        if (totalItemsCurrent > 0) {
            allCardsCurrent.forEach((card, index) => {
                if (index >= maxItemsVisible) {
                    card.remove();
                }
            });
            if (totalItemsCurrent > maxItemsVisible) {
                buttonLoad.classList.add("is-visible")
            }
            else {
                buttonLoad.classList.remove("is-visible")
            }
        }
        else {
            cardContainer.textContent = "no results found"
        }
    }
    function badgeNewCheck(cards: NodeListOf<HTMLDivElement>):void{ 
        cards.forEach((item:any) => {
            const dateCreationAttr = item.getAttribute('data-creation')
            const dateEvaluationAttr = item.getAttribute('data-evaluation')
            const badgeNew = item.querySelector('.badge-new-wrapper') as HTMLHtmlElement
            const badgeNewMobile = item.querySelector('.badge-new-wrapper-mob') as HTMLHtmlElement

            if ((item.hasAttribute("data-creation") && item.hasAttribute("data-evaluation")) &&
                ((dateEvaluationAttr != '') &&
                    (dateCreationAttr != '')) && ((dateEvaluationAttr != 'undefined') && (dateCreationAttr != 'undefined'))) {
                let dateNow = new Date()
                dateNow.setHours(0, 0, 0, 0)

                let dateCreation = new Date(String(dateCreationAttr))
                dateCreation.setHours(0, 0, 0, 0)

                let dateEvaluation = new Date(String(dateEvaluationAttr))
                dateEvaluation.setHours(0, 0, 0, 0)

                if (dateCreation > dateNow || dateEvaluation <= dateNow) {
                    if (badgeNew) {
                        badgeNew.classList.add("d-none")
                    }
                    if (badgeNewMobile) {
                        badgeNewMobile.classList.add("d-none")
                    }
                }
            } else {
                if (badgeNew) {
                    badgeNew.classList.add("d-none")
                }
                if (badgeNewMobile) {
                    badgeNewMobile.classList.add("d-none")
                }
            }
        })
    }    

}