import "./tiles-grid.scss"



import {carousels, getTargets} from "../../ts/utilities";

export default function TilesGridCarousel() {

    const $targets = getTargets('tiles-grid-carousel');
    carousels( {targets: $targets, id: 'tiles-grid-carousel', spaceBetween: 0, spaceBetweenMobile: 16 , dynamicBullets: true,  slidesToShow: 'auto', slidesMoveMobile:1});
}



