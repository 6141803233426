import './header.scss';

export default function Header() {
    const $header = document.querySelector('.header-component') as HTMLElement;

    if ($header) {
        const $menuOpener = $header.querySelector('.menu-opener') as HTMLElement;
        const $menuCloser = $header.querySelector('.close-menu') as HTMLElement;
        const $searchOpener = $header.querySelector('.search label') as HTMLLabelElement;
        const $searchCloser = $header.querySelector('.search-modal label') as HTMLLabelElement;
        const $inputContainer = $header.querySelector('.search-modal .input-container') as HTMLElement;
        const $input = $inputContainer.querySelector('input') as HTMLInputElement;
        const $headerAffixed = $header.querySelector('.header-affixed') as HTMLElement;
        const headerHeight = $header.clientHeight;
        const masks = $header.querySelectorAll('.mask') as NodeListOf<HTMLDivElement>;
        const links = $header.querySelectorAll('a') as NodeListOf<HTMLAnchorElement>;
        const subMenuDesktop = $header.querySelectorAll('.sub-menu-desktop .sub-menu-list');
        const subMenuLinks = $header.querySelectorAll('.sub-menu a') 
        const subMenuMobile = $header.querySelectorAll('.sub-menu-mobile') 
        
        subMenuLinks.forEach((link:any) => {
            if (link.getAttribute("href") == '#'){
                link.setAttribute("href", "javascript:void(0)")
                link.classList.add('is-locked')
            }
        })

        if (subMenuMobile.length > 0){
            
            subMenuMobile.forEach((subMenuMobileItem:any) => {
                let firstLevelLink = subMenuMobileItem.querySelectorAll('.first-level-link');
                
                if (firstLevelLink.length > 0){
                    firstLevelLink.forEach((firstLevelLinkItem:any) => {
                        let secondLevelList = firstLevelLinkItem.querySelectorAll('.second-level-list');
                        let closeSubmenuBtn = firstLevelLinkItem.querySelector('.close-submenu');
                        
                        if (secondLevelList.length > 0){
                            firstLevelLinkItem.classList.add('has-submenu');
                            closeSubmenuBtn.addEventListener('click', () => {

                                if(!firstLevelLinkItem.classList.contains('opened')){
                                    firstLevelLinkItem.classList.add('opened')
                                    let innerDivPos = firstLevelLinkItem.offsetTop
                                    subMenuMobileItem.scrollTo({ top: innerDivPos, behavior: 'smooth' })
                                } else {
                                    firstLevelLinkItem.classList.remove('opened');
                                }
        
                            });                    
                        }
                    });
                }                

            })

        }

        subMenuDesktop!.forEach( (menu:any) => {
            if(menu.querySelectorAll('a').length > 68){
                menu?.classList.add("column-count")
            }      
        });

        links.forEach(link => {
            link.addEventListener('click', () => {
                $header.classList.remove('has-menu-open');
                document.body.classList.remove('no-scroll');
            });
        });

        masks.forEach(mask => {
            mask.style.height = `${window.innerHeight}px`;
        });
        if ($menuOpener && $menuCloser) {
            $menuOpener.addEventListener('click', () => {
                $header.classList.add('has-menu-open');
                if (!document.body.classList.contains('no-scroll')) {
                    document.body.classList.add('no-scroll');
                }
            });

            $menuCloser.addEventListener('click', () => {
                $header.classList.remove('has-menu-open');
                if (document.body.classList.contains('no-scroll')) {
                    document.body.classList.remove('no-scroll');
                }
            });
        }
        if ($searchOpener && $searchCloser) {
            searchTrigger();
            $searchOpener.addEventListener('click', () => {
                $header.classList.add('has-search-open');
                $input.focus();
                if (!document.body.classList.contains('no-scroll')) {
                    document.body.classList.add('no-scroll');
                }
            });

            $searchCloser.addEventListener('click', () => {
                $header.classList.remove('has-search-open');
                if (document.body.classList.contains('no-scroll')) {
                    document.body.classList.remove('no-scroll');
                }
            });
        }

        function searchTrigger() {
            const $triggerSearch = $inputContainer.querySelector('[data-search-trigger]') as HTMLElement;
            const link = $triggerSearch.getAttribute('data-search-link') as string;

            $triggerSearch.addEventListener('click', () => {
                const inputValue = `?q=${$input.value}`;
                window.location.href = link + inputValue;
            });
            $input.addEventListener('keypress', e => {
                if (e.key === 'Enter') {
                    const inputValue = `?q=${$input.value}`;
                    window.location.href = link + inputValue;
                }
            });
        }

        function headerSticky() {
            window.addEventListener('scroll', () => {
                if (window.scrollY > headerHeight + 10) {
                    $header.classList.add('is-fixed');
                    $headerAffixed.style.height = `${headerHeight}px`;
                } else {
                    $header.classList.remove('is-fixed');
                    $headerAffixed.style.height = '0px';
                }
            });
        }

        window.addEventListener('resize', () => {
            masks.forEach(mask => {
                mask.style.height = `${window.innerHeight}px`;
            });
        });

        headerSticky();
    }
}
