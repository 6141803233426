export enum COMPONENT {
    READY = 'ready',
    HANDLER = 'data-handler',
    COLLECTION = 'data-handler-collection',
}
export enum FILTER_TYPE {
    BRAND = "select-brand",
    CATEGORY = "select-category"
}

export enum CONTAINER_TYPE {
    ARTICLE_CONTAINER = "article-grid",
    BRAND_CONTAINER = "grid-brand",
    PRODUCT_CONTAINER = "grid-product-item",
    RECIPE_CONTAINER = "grid-recipe-item"
}
export enum CHILD_TYPE {
    ARTICLE_CHILD = "article",
    BRAND_CHILD = "brand-wrapper",
    PRODUCT_CHILD = "article",
    RECIPE_CHILD = "article"
}
