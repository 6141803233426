import './product-tabs-content.scss';
import {carousels, getTargets} from "../../ts/utilities";

export default function ProductTabsContent() {
    const $targets = getTargets('tab-carousel');
    carousels(
        {
            targets: $targets,
            id: 'tab-carousel',
            spaceBetween: 0,
            spaceBetweenTablet: 16,
            spaceBetweenMobile: 32,
            dynamicBullets: true,
            slidesToShow: 'auto',
            slidesToShowTablet: 3,
            slidesToShowMobile: 'auto',
            slidesMoveTablet:1,
            slidesMoveMobile:1
        }
    );
}