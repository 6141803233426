import './hero-banner.scss';
import { getTargets } from '../../ts/utilities';

export default function HeroBanner() {
    const $targets = getTargets('hero-banner');
    if ($targets.length > 0) {
        $targets.forEach(($target: HTMLElement) => {
            const button = $target.querySelector('[data-play-video]') as HTMLElement;
            const video = $target.querySelector('video') as HTMLVideoElement & {
                mozRequestFullScreen(): Promise<void>;
                webkitRequestFullscreen(): Promise<void>;
                webkitEnterFullscreen(): Promise<void>;
                msRequestFullScreen(): Promise<void>;
                webkitFullscreenElement(): Promise<void>;
            };
            const parallaxCardList = getTargets('parallax-card-list');
            if (parallaxCardList.length > 0) {
                parallaxCardList.forEach((item: any) => {
                    item.style.visibility = 'visible';
                });
            }

            if (video && button) {
                button.addEventListener('click', () => {
                    if (video.requestFullscreen) {
                        video.requestFullscreen();
                    } else if (video.webkitRequestFullscreen) {
                        video.webkitRequestFullscreen();
                    } else if (video.msRequestFullScreen) {
                        video.msRequestFullScreen();
                    } else if (video.webkitEnterFullscreen) {
                        video.webkitEnterFullscreen();
                    }
                    video.play();
                });

                video.addEventListener('webkitfullscreenchange', function () {
                    video.muted = true;
                    if (parallaxCardList.length > 0) {
                        parallaxCardList.forEach((item: any) => {
                            if (item.style.visibility !== 'visible') {
                                item.style.visibility = 'visible';
                            } else {
                                item.style.visibility = 'hidden';
                            }
                        });
                    }
                });

                video.addEventListener('fullscreenchange', function () {
                    video.muted = true;
                });
            }
        });
    }
}
