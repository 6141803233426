import './hero-carousel.scss';
import { carousels, getTargets } from '../../ts/utilities';

export default function HeroCarousel() {
    const heroCarousels = getTargets('hero-carousel');
    carousels({
        targets: heroCarousels,
        id: 'hero-carousel',
        slidesToShow: 'auto',
        spaceBetween: 0,
        slidesMove: 1,
        dynamicBullets: false,
    });

    if (heroCarousels.length > 0) {
        heroCarousels.forEach(($target: HTMLElement) => {
            const activeSlide = $target.querySelector('.swiper-pagination-bullet') as HTMLElement;
            activeSlide.classList.remove('swiper-pagination-bullet-active');
            setTimeout(function () {
                activeSlide.classList.add('swiper-pagination-bullet-active');
            }, 1);

            const youtubePlayer = $target.querySelectorAll('iframe');
            const swiperSlideYoutube = $target.querySelector('.swiper-slide.youtube-video') as HTMLDivElement;

            if (youtubePlayer.length > 0 && swiperSlideYoutube && !swiperSlideYoutube.classList.contains('swiper-slide-active')) {
                youtubePlayer.forEach((item:any) => {
                    item.setAttribute('src', item.src + '&enablejsapi=1' + '&mute=1&controls=0');
                })
            }
        });
    }
}
