import "./side-grid.scss"
import {getTargets} from "../../ts/utilities";
export default function SideGridType () {
    const $targets = getTargets('side-grid') as any;
    if($targets.length > 0) {
        $targets.forEach(($target : any) => {
            const prevElement = $target.parentElement?.previousSibling?.previousSibling;
            if(prevElement) {
                if(prevElement.classList.contains('carousel') || prevElement.classList.contains('carousel-component')) {
                    $target.classList.add('has-carousel');
                }
            }

        })
    }

}