import { COMPONENT } from './enums';
import Swiper, { Autoplay, Grid, Navigation, Pagination } from 'swiper';
import lax from 'lax.js';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'custom-select/build/custom-select.css';

export function getUniqueID() {
    let prevId = parseInt(sessionStorage.getItem('__uuidIndex') || '0');
    const id = ++prevId;
    sessionStorage.setItem('__uuidIndex', id.toString());
    return `idx-uuid-${id}`;
}

export function debounce(callback: any, timeout = 500) {
    let timer: any;
    //@ts-ignore
    let context: any = this;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback.apply(context, args);
        }, timeout);
    };
}

export function getTargets(id: string, returnAll: boolean = false) {
    const attr = `data-component-${id}`;
    const $targets = document.querySelectorAll(`[${attr}]`);
    const results = [].slice.call($targets);
    return returnAll
        ? results
        : results.filter(($component: HTMLElement) => {
              return !getStatus($component, id);
          });
}

export function getHandlers($component: HTMLElement) {
    const $handlers = $component.querySelectorAll(`[${COMPONENT.HANDLER}]`);
    return [].slice.call($handlers).reduce((handlers: any, $handler: HTMLElement) => {
        const handler = $handler.getAttribute(COMPONENT.HANDLER);
        const isCollection = $handler.hasAttribute(COMPONENT.COLLECTION);
        if (handler) {
            if (isCollection) {
                const pointer = handlers[handler];
                if (pointer) {
                    handlers[handler].push($handler);
                } else {
                    handlers[handler] = [$handler];
                }
            } else {
                handlers[handler] = $handler;
            }
        }
        return handlers;
    }, {});
}

export function setReadyStatus($target: HTMLElement, id: string) {
    const attr = `data-component-${id}`;
    $target.setAttribute(attr, COMPONENT.READY);
}

export function getStatus($target: HTMLElement, id: string) {
    const attr = `data-component-${id}`;
    return $target && $target.getAttribute(attr) === COMPONENT.READY;
}

export function groupArray({ arr, size }: { arr: any; size: any }) {
    let array2 = [] as any;
    const createGroup = ({ arr, size }: { arr: any; size: any }) => {
        if (arr.length <= size) {
            array2.push(arr);
        } else {
            let group = arr.slice(0, size);
            let remainder = arr.slice(size);
            array2.push(group);
            createGroup({ arr: remainder, size: size });
        }
    };
    createGroup({ arr: arr, size: size });
    return array2;
}

export function carousels({
    targets,
    id,
    spaceBetween,
    spaceBetweenTablet,
    spaceBetweenMobile,
    dynamicBullets,
    slidesToShow,
    slidesToShowTablet,
    slidesToShowMobile,
    slidesMove,
    slidesMoveTablet,
    slidesMoveMobile,
    navigation,
}: {
    targets: any;
    id: string;
    spaceBetween: number;
    spaceBetweenTablet?: number;
    spaceBetweenMobile?: number;
    dynamicBullets?: boolean;
    slidesToShow?: any;
    slidesToShowTablet?: any;
    slidesToShowMobile?: any;
    slidesMove?: number;
    slidesMoveTablet?: number;
    slidesMoveMobile?: number;
    navigation?: boolean;
}) {
    if (targets.length > 0) {
        targets.forEach((target: any, index: number) => {
            setReadyStatus(target, id);
            const tileCarousel = target.querySelector('.swiper-container') as HTMLDivElement;
            const pagination = target.querySelector('.swiper-pagination') as HTMLDivElement;
            const navigationPrev = target.querySelector('.arrow-prev') as HTMLDivElement;
            const navigationNext = target.querySelector('.arrow-next') as HTMLDivElement;

            const autoPlay = Boolean(target.getAttribute('data-autoplay') === 'true');
            const timeSlide = autoPlay ? Number(target.getAttribute('data-timing-autoplay') * 1000) : 0;

            tileCarousel.classList.add(`${id}-${index}`);
            pagination.classList.add(`pagination-${id}-${index}`);

            if (navigationPrev && navigationNext) {
                navigationPrev.classList.add(`prev-${id}-${index}`);
                navigationNext.classList.add(`next-${id}-${index}`);
            }

            const swiper = new Swiper(`.${id}-${index}`, {
                modules: [Navigation, Pagination, Grid, Autoplay],
                slidesPerGroup: slidesMove ? slidesMove : 0,

                pagination: {
                    el: `.pagination-${id}-${index}`,
                    dynamicBullets: dynamicBullets,
                    clickable: true,
                    renderBullet: function () {
                        return (
                            '<button class="swiper-pagination-bullet">' +
                            '<div class="before-wrapper"><div class="before" style="transition-duration: ' +
                            timeSlide +
                            'ms">' +
                            '</div></div>' +
                            '</button>'
                        );
                    },
                },
                navigation: navigation
                    ? {
                          prevEl: `.prev-${id}-${index}`,
                          nextEl: `.next-${id}-${index}`,
                      }
                    : {},
                autoplay: autoPlay
                    ? {
                          disableOnInteraction: false,
                          pauseOnMouseEnter: true,
                          delay: timeSlide,
                      }
                    : false,
                watchOverflow: true,
                resizeObserver: true,
                observeParents: true,
                on: {
                    slideChange(s) {
                        s;
                        const targetclass = `.${id}-${index}` as string;
                        const $targetCurrent = target?.querySelector(`${targetclass}`);
                        const progressCircles = Array.from($targetCurrent.querySelectorAll('.swiper-pagination-bullet'));
                        const currentSlide = $targetCurrent.querySelector('.swiper-pagination-bullet.swiper-pagination-bullet-active') as HTMLDivElement;
                        const activeSlide = progressCircles.indexOf(currentSlide);
                        const index_currentSlide = swiper.realIndex;
                        const currentSlideActive = swiper.slides[index_currentSlide];

                        if (video.length > 0 && !currentSlideActive.classList.contains('video')) {
                            video.forEach((item: any) => {
                                item.pause();
                            });
                        } else if (youtubePlayer.length > 0 && !currentSlideActive.classList.contains('youtube-video')) {
                            youtubePlayer.forEach((item: any) => {
                                item.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                            });
                        }

                        if (video.length > 0 && currentSlideActive.classList.contains('video')) {
                            video.forEach((item: any) => {
                                if (item) {
                                    icnPlay.forEach((play: any) => {
                                        if (play.classList.contains('d-none')) {
                                            item.play();
                                        }

                                        play.addEventListener('click', () => {
                                            play.classList.add('d-none');
                                            item.play();
                                            icnPause.forEach((pause: any) => {
                                                pause.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnPause.forEach((pause: any) => {
                                        pause.addEventListener('click', () => {
                                            pause.classList.add('d-none');
                                            item.pause();
                                            icnPlay.forEach((play: any) => {
                                                play.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnMute.forEach((mute: any) => {
                                        mute.addEventListener('click', () => {
                                            item.muted = false;
                                            mute.classList.add('d-none');
                                            icnVolume.forEach((volume: any) => {
                                                volume.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnVolume.forEach((volume: any) => {
                                        volume.addEventListener('click', () => {
                                            item.muted = true;
                                            volume.classList.add('d-none');
                                            icnMute.forEach((mute: any) => {
                                                mute.classList.remove('d-none');
                                            });
                                        });
                                    });
                                }
                            });
                        } else if (youtubePlayer.length > 0 && currentSlideActive.classList.contains('youtube-video')) {
                            youtubePlayer.forEach((item: any) => {
                                if (item) {
                                    icnPlayYt.forEach((play: any) => {
                                        if (play.classList.contains('d-none')) {
                                            item.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                                        }

                                        play.addEventListener('click', () => {
                                            play.classList.add('d-none');
                                            item.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                                            icnPauseYt.forEach((pause: any) => {
                                                pause.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnPauseYt.forEach((pause: any) => {
                                        pause.addEventListener('click', () => {
                                            pause.classList.add('d-none');
                                            item.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                            icnPlayYt.forEach((play: any) => {
                                                play.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnMuteYt.forEach((mute: any) => {
                                        mute.addEventListener('click', () => {
                                            mute.classList.add('d-none');
                                            item.contentWindow.postMessage('{"event":"command","func":"unMute","args":""}', '*');
                                            icnVolumeYt.forEach((volume: any) => {
                                                volume.classList.remove('d-none');
                                            });
                                        });
                                    });

                                    icnVolumeYt.forEach((volume: any) => {
                                        volume.addEventListener('click', () => {
                                            volume.classList.add('d-none');
                                            item.contentWindow.postMessage('{"event":"command","func":"mute","args":""}', '*');
                                            icnMuteYt.forEach((mute: any) => {
                                                mute.classList.remove('d-none');
                                            });
                                        });
                                    });
                                }
                            });
                        }

                        if (autoPlay) {
                            progressCircles.forEach((bullet: any, index) => {
                                if (index < activeSlide) {
                                    bullet.classList.add('is-loaded');
                                } else {
                                    bullet.classList.remove('is-loaded');
                                }
                            });
                        }
                    },
                },
                breakpoints: {
                    0: {
                        spaceBetween: spaceBetweenMobile ? spaceBetweenMobile : spaceBetween,
                        slidesPerView: slidesToShowMobile ? slidesToShowMobile : slidesToShow,
                        slidesPerGroup: slidesMoveMobile ? slidesMoveMobile : slidesMove ? slidesMove : 0,
                        simulateTouch: true,
                        grabCursor: true,
                    },
                    576: {
                        spaceBetween: spaceBetweenTablet ? spaceBetweenTablet : spaceBetween,
                        slidesPerView: slidesToShowTablet ? slidesToShowTablet : slidesToShow,
                        slidesPerGroup: slidesMoveTablet ? slidesMoveTablet : slidesMove ? slidesMove : 0,
                        simulateTouch: true,
                        grabCursor: true,
                    },
                    769: {
                        spaceBetween: spaceBetween,
                        slidesPerView: slidesToShow,
                        slidesPerGroup: slidesMove ? slidesMove : 0,
                        simulateTouch: false,
                        grabCursor: false,
                    },
                },
            });

            const video = target.querySelectorAll('video');
            const youtubePlayer = target.querySelectorAll('iframe');
            const icnPlay = target.querySelectorAll('.icn-play-video.video');
            const icnPause = target.querySelectorAll('.icn-pause.video');
            const icnMute = target.querySelectorAll('.icn-mute.video');
            const icnVolume = target.querySelectorAll('.icn-volume.video');
            const icnPlayYt = target.querySelectorAll('.icn-play-video.youtube-video');
            const icnPauseYt = target.querySelectorAll('.icn-pause.youtube-video');
            const icnMuteYt = target.querySelectorAll('.icn-mute.youtube-video');
            const icnVolumeYt = target.querySelectorAll('.icn-volume.youtube-video');
            const index_currentSlide = swiper.realIndex;
            const currentSlideActive = swiper.slides[index_currentSlide];

            if (video.length > 0 && currentSlideActive.classList.contains('video')) {
                video.forEach((item: any) => {
                    if (item) {
                        icnPlay.forEach((play: any) => {
                            if (play.classList.contains('d-none')) {
                                item.play();
                            }
                            play.addEventListener('click', () => {
                                item.play();
                                play.classList.add('d-none');
                                icnPause.forEach((pause: any) => {
                                    pause.classList.remove('d-none');
                                });
                            });
                        });

                        icnPause.forEach((pause: any) => {
                            pause.addEventListener('click', () => {
                                item.pause();
                                pause.classList.add('d-none');
                                icnPlay.forEach((play: any) => {
                                    play.classList.remove('d-none');
                                });
                            });
                        });

                        icnMute.forEach((mute: any) => {
                            mute.addEventListener('click', () => {
                                item.muted = false;
                                mute.classList.add('d-none');
                                icnVolume.forEach((volume: any) => {
                                    volume.classList.remove('d-none');
                                });
                            });
                        });

                        icnVolume.forEach((volume: any) => {
                            volume.addEventListener('click', () => {
                                item.muted = true;
                                volume.classList.add('d-none');
                                icnMute.forEach((mute: any) => {
                                    mute.classList.remove('d-none');
                                });
                            });
                        });
                    }
                });
            } else if (youtubePlayer.length > 0 && currentSlideActive.classList.contains('youtube-video')) {
                youtubePlayer.forEach((item: any) => {
                    if (item) {
                        icnPlayYt.forEach((play: any) => {
                            if (play.classList.contains('d-none')) {
                                item.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                            }

                            play.addEventListener('click', () => {
                                item.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                                play.classList.add('d-none');
                                icnPauseYt.forEach((pause: any) => {
                                    pause.classList.remove('d-none');
                                });
                            });
                        });

                        icnPauseYt.forEach((pause: any) => {
                            pause.addEventListener('click', () => {
                                item.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                                pause.classList.add('d-none');
                                icnPlayYt.forEach((play: any) => {
                                    play.classList.remove('d-none');
                                });
                            });
                        });

                        icnMuteYt.forEach((mute: any) => {
                            mute.addEventListener('click', () => {
                                mute.classList.add('d-none');
                                item.contentWindow.postMessage('{"event":"command","func":"unMute","args":""}', '*');
                                icnVolumeYt.forEach((volume: any) => {
                                    volume.classList.remove('d-none');
                                });
                            });
                        });

                        icnVolumeYt.forEach((volume: any) => {
                            volume.addEventListener('click', () => {
                                volume.classList.add('d-none');
                                item.contentWindow.postMessage('{"event":"command","func":"mute","args":""}', '*');
                                icnMuteYt.forEach((mute: any) => {
                                    mute.classList.remove('d-none');
                                });
                            });
                        });
                    }
                });
            }

            if (video.length > 0 && !currentSlideActive.classList.contains('video')) {
                video.forEach((item: any) => {
                    item.pause();
                });
            } else if (youtubePlayer.length > 0 && !currentSlideActive.classList.contains('youtube-video')) {
                youtubePlayer.forEach((item: any) => {
                    item.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                });
            }

            swiper.init();
        });
    }
}

export function ParallaxAnimation() {
    const $targetsCards = getTargets('parallax-card-list');
    const $targetsDescription = getTargets('description-banner');

    if ($targetsCards.length > 0 || $targetsDescription.length > 0) {
        lax.init();

        lax.addDriver(
            'scrollY',
            function () {
                return window.scrollY;
            },
            {
                inertiaEnabled: true,
            }
        );

        if ($targetsCards.length > 0) {
            lax.addElements('.image.back', {
                scrollY: {
                    translateY: [
                        ['elInY', 'elCenterY', 'elOutY'],
                        [70, 50, 30],
                        {
                            transform: '400ms translateY ',
                        },
                    ],
                },
            });

            lax.addElements('.image.back.central', {
                scrollY: {
                    translateY: [
                        ['elInY', 'elCenterY', 'elOutY'],
                        [50, 35, 30],
                        {
                            transform: '400ms translateY ',
                        },
                    ],
                },
            });

            lax.addElements('.text-container.parallax', {
                scrollY: {
                    translateY: [
                        ['elInY', 'elCenterY', 'elOutY'],
                        [0, 20, 30],
                        {
                            transform: '400ms translateY ',
                        },
                    ],
                },
            });

            lax.addElements('.text-container.parallax.central', {
                scrollY: {
                    translateY: [
                        ['elInY', 'elCenterY', 'elOutY'],
                        [-20, 10, 60],
                        {
                            transform: '400ms translateY ',
                        },
                    ],
                },
            });
        }

        if ($targetsDescription.length > 0) {
            lax.addElements('.front-image.parallax', {
                scrollY: {
                    translateY: [
                        ['elInY', 'elCenterY', 'elOutY'],
                        [50, 15, -20],
                        {
                            transform: '400ms translateY ',
                        },
                    ],
                },
            });
        }
    }
}
