import './product-tabs.scss';
import { getTargets, setReadyStatus } from '../../ts/utilities';

export default function ProductTabs() {
    const $targets = getTargets('tabs-product');

    if ($targets.length > 0) {
        $targets.forEach(($target: HTMLElement) => {
            setReadyStatus($target, 'tab-products');
            ui($target);
        });
    }
}

function ui($target: HTMLElement) {
    const $labels = $target.querySelectorAll('.tab');

    if ($labels.length > 0) {
        $labels.forEach($label => {
            $label.addEventListener('click', () => {
                $labels.forEach($item => {
                    $item.classList.remove('is-active');
                });
                $label.classList.add('is-active');
            });
        });
    }
}
