import './history.scss';
import { debounce, getHandlers, getTargets, getUniqueID, setReadyStatus } from '../../ts/utilities';

type HistoryHandlers = {
    slider: HTMLElement;
    slides: Array<HTMLElement>;
    timeline: HTMLElement;
    timelineLabels: Array<HTMLInputElement>;
};

export default function History() {
    const $targets = getTargets('history');

    $targets.forEach(($target: HTMLElement) => {
        const handlers = getHandlers($target);
        setReadyStatus($target, 'history');

        setInitialSlide($target, handlers);
        ui(handlers);
    });
}

function ui(handlers: HistoryHandlers) {
    const { slider, slides, timeline, timelineLabels } = handlers;

    /*
     * This variable regulates which carousel takes control over the other, based on tracked user interaction.
     * TRUE: Scrolling takes precedence over clicks
     * FALSE: Click takes precedence over scroll
     * */
    let userScrollInteraction = false;
    const uuid = getUniqueID();

    let observer = new IntersectionObserver(
        entries => {
            const entry = entries.find(entry => entry.isIntersecting);
            if (entry) {
                const id = entry.target.getAttribute('id');
                const $timeCheck = timeline.querySelector(`[data-href='#${id}'] > input`) as HTMLElement;
                if (userScrollInteraction && $timeCheck) {
                    $timeCheck.scrollIntoView({
                        behavior: 'smooth',
                    });
                    $timeCheck.click();
                }
            }
        },
        {
            root: slider,
            rootMargin: '0px',
            threshold: 0.25,
        }
    );

    if (timelineLabels && timelineLabels.length > 0) {
        timelineLabels.forEach(($item: HTMLElement) => {
            $item.setAttribute('name', uuid);
            $item.addEventListener('change', () => {
                const $check = timelineLabels.find(($input: HTMLInputElement) => {
                    return $input.checked;
                });
                requestAnimationFrame(() => {
                    $check && $check.parentElement?.click();
                });
            });
        });
    }

    if (slider) {
        slider.addEventListener(
            'scroll',
            debounce(() => {
                userScrollInteraction = true;
                slider.classList.remove('is-locked');
            }, 200)
        );
    }

    if (slides && slides.length > 0) {
        slides.forEach($slide => {
            observer.observe($slide);
        });
    }


    if (timeline) {
        [].slice.call(timeline.querySelectorAll('.label')).forEach(($a: HTMLDivElement) => {
            $a.addEventListener('click', (evt) => {

                if (evt.isTrusted) {
                    userScrollInteraction = false;
                    slider.classList.add('is-locked');
                }
                const target = $a.getAttribute('data-href')!;
                const $target = document.querySelector(target);
                if($target) {
                    $target.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "center"
                    })
                }
            })
        });
        timeline.addEventListener('click', (e: MouseEvent) => {
            if (e.isTrusted) {
                userScrollInteraction = false;
                slider.classList.add('is-locked');
            }
        });
    }
}

function setInitialSlide($target: HTMLElement, handlers: HistoryHandlers) {
    const { slider, timeline } = handlers;
    const id = $target.getAttribute('data-index-begin');

    if (slider && timeline) {
        const $slide = slider.querySelector(`#${id}`) as HTMLElement;
        const $timelineItem = timeline.querySelector(`[data-href="#${id}"] input`) as HTMLInputElement;

        if($slide && $timelineItem) {
            $target.classList.add('no-animations');
            slider.scrollLeft = $slide.offsetLeft;
            timeline.scrollLeft = $timelineItem.offsetLeft;
            $timelineItem.checked = true;
            $target.classList.remove('no-animations');
        }
    }
}
