import './brand-grid.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { carousels, getTargets } from '../../ts/utilities';

export default function BrandGrid() {
    const $targets = getTargets('brand-grid');

    carousels({
        targets: $targets,
        id: 'brand-grid',
        spaceBetween: 0,
        spaceBetweenMobile: 16,
        dynamicBullets: true,
        slidesToShow: 'auto',
        slidesMove: 1,
        navigation: true,
    });
}
