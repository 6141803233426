import './search-results.scss';

export default function SearchResults() {
    const $target = document.querySelector('[data-component-search-results]');

    const country = $target?.getAttribute('data-country');
    const language = $target?.getAttribute('data-language');
    const isAuthorMode = $target?.getAttribute('data-author-mode') as any;

    const querySearchVariable = `/bin/search.json?type=search&country=${country}&language=${language}&q=unset&maxItemVisible=8&offset=0`;

    const composedUrl = String(window.location.origin + querySearchVariable);
    const urlSearch = new URL(composedUrl);
    let dataFound: any;
    let urlValueToAdd = new URL(String(window.location.href)).searchParams;
    let valueToReplace = urlValueToAdd.get('q');

    const params_search = urlSearch.searchParams;
    params_search.set('q', String(valueToReplace));
    urlSearch.search = params_search.toString();

    let newSearchUrl = urlSearch.toString();

    if (newSearchUrl && valueToReplace) {
        detectSearchResults(newSearchUrl, valueToReplace, false, isAuthorMode);
    }

    function detectSearchResults(path: string, valueInput: string, loadMore: boolean, isAuthorMode: boolean) {
        fetch(path)
            .then(response => {
                return response.json();
            })
            .then(data => {
                dataFound = data;

                if (loadMore) {
                    loadMoreResults(data, $target, isAuthorMode);
                } else {
                    composeResults(data, $target, valueInput, path, isAuthorMode);
                }
            })
            .catch(error => {
                dataFound = error;
                console.error('Error:', error);
            });
    }

    function composeResults(results: any, $target: any, valueInput: string, path: string, isAuthorMode: boolean) {
        const $searchPart = $target.querySelector('.search-part') as HTMLDivElement;
        const $input = $searchPart.querySelector('input') as HTMLInputElement;
        $input.value = valueInput;
        const $resultsContainer = $target?.querySelector('.results-container') as HTMLDivElement;

        if ($resultsContainer) {
            $resultsContainer.innerHTML = '';
        }

        if ($target) {
            const $resultsContainer = $target.querySelector('.results-container') as HTMLDivElement;
            const button = $target.querySelector('[data-load-more]') as HTMLDivElement;
            const countText = $resultsContainer.getAttribute('data-total-results-text');
            const resultsText = countText?.replace('{total}', String(results.total)) as string;
            const resultsWrapper = document.createElement('div');
            resultsWrapper.classList.add('results-count');
            const wrapper = document.createElement('div');
            wrapper.classList.add('wrapper');
            wrapper.innerHTML = resultsText;
            resultsWrapper.appendChild(wrapper);

            $resultsContainer.appendChild(resultsWrapper);
            if (results && results.hits?.length > 0) {
                const $wrapperResults = document.createElement('div');

                $wrapperResults.classList.add('wrapper-results');

                results.hits.map((hit: any) => {
                    const linkUrl = Boolean(isAuthorMode) ? hit['jcr:path'] + '.html' : '/' + hit['jcr:path'].split('/').slice(3) + '.html';
                    const descriptionText = hit['jcr:content']?.['jcr:description'];
                    const titleText = hit['jcr:content']?.['jcr:title'];
                    const categoryText = hit['jcr:content']?.['cq:template'].split('/').pop();
                    const resulItem = document.createElement('div');
                    resulItem.classList.add('result-item');
                    const link = document.createElement('a');
                    link.setAttribute('href', linkUrl.replaceAll(',', '/'));
                    const wrapper = document.createElement('div');
                    wrapper.classList.add('wrapper');
                    const category = document.createElement('div');
                    category.classList.add('category');
                    category.innerHTML = categoryText ? String(categoryText.split('-')[0]) : '';
                    const title = document.createElement('div');
                    title.classList.add('title');
                    title.innerHTML = titleText ? titleText : '';
                    const description = document.createElement('div');
                    description.classList.add('description');
                    description.innerHTML = descriptionText ? descriptionText : '';
                    categoryText ? wrapper.appendChild(category) : null;
                    titleText ? wrapper.appendChild(title) : null;
                    descriptionText ? wrapper.appendChild(description) : null;
                    link.appendChild(wrapper);
                    resulItem.appendChild(link);
                    $wrapperResults.appendChild(resulItem);
                });

                $resultsContainer.appendChild($wrapperResults);
            }

            if (results.results + results.offset < results.total && results.total > 8) {
                button.classList.add('is-visible');
                button.addEventListener('click', () => {
                    loadMore(path);
                });
            } else {
                button.classList.remove('is-visible');
            }
        }
    }

    function loadMore(path: string) {
        const currentUrl = new URL(path);
        const params = currentUrl.searchParams;
        const inputValue = params?.get('q') as string;
        const currentOffset = dataFound.offset;
        const offsetToUpdate = Number(currentOffset) + 8;
        params.set('offset', String(offsetToUpdate));

        currentUrl.search = params.toString();
        newSearchUrl = currentUrl.toString();

        detectSearchResults(newSearchUrl, inputValue, true, isAuthorMode);
    }

    function loadMoreResults(results: any, $target: any, isAuthorMode: boolean) {
        const button = $target.querySelector('[data-load-more]') as HTMLDivElement;

        const $resultsContainer = $target.querySelector('.results-container') as HTMLDivElement;

        const $wrapperResults = $target.querySelector('.wrapper-results');

        results.hits.map((hit: any) => {
            const linkUrl = Boolean(isAuthorMode) ? hit['jcr:path'] + '.html' : '/' + hit['jcr:path'].split('/').slice(3) + '.html';
            const descriptionText = hit['jcr:content']?.['jcr:description'];
            const titleText = hit['jcr:content']?.['jcr:title'];
            const categoryText = hit['jcr:content']?.['cq:template'].split('/').pop();
            const resulItem = document.createElement('div');
            resulItem.classList.add('result-item');
            const link = document.createElement('a');
            link.setAttribute('href', linkUrl.replaceAll(',', '/'));
            const wrapper = document.createElement('div');
            wrapper.classList.add('wrapper');
            const category = document.createElement('div');
            category.classList.add('category');
            category.innerHTML= categoryText ? String(categoryText.split('-')[0]) : '';
            const title = document.createElement('div');
            title.classList.add('title');
            title.innerHTML = titleText ? titleText : '';
            const description = document.createElement('div');
            description.classList.add('description');
            description.innerHTML = descriptionText ? descriptionText : '';
            categoryText ? wrapper.appendChild(category) : null;
            titleText ? wrapper.appendChild(title) : null;
            descriptionText ? wrapper.appendChild(description) : null;
            link.appendChild(wrapper);
            resulItem.appendChild(link);
            $wrapperResults.appendChild(resulItem);
        });

        $resultsContainer.appendChild($wrapperResults);

        if (results.results + results.offset < results.total && results.total > 8) {
            button?.classList.add('is-visible');
        } else {
            button?.classList.remove('is-visible');
        }
    }

    function searchInput() {
        if ($target) {
            const $searchPart = $target.querySelector('.search-part') as HTMLDivElement;
            const $triggerSearch = $searchPart.querySelector('[data-search-trigger]') as HTMLElement;
            const $input = $searchPart.querySelector('input') as HTMLInputElement;
            const isAuthorMode = $target?.getAttribute('data-author-mode') as any;
            const urlSearch = new URL(composedUrl);
            const params_search = urlSearch.searchParams;
            newSearchUrl = urlSearch.toString();

            $triggerSearch.addEventListener('click', () => {
                const inputValueToUpDate = $input.value;
                params_search.set('q', String(inputValueToUpDate));
                urlSearch.search = params_search.toString();
                newSearchUrl = urlSearch.toString();

                const urlSearchCurrentPage = new URL(window.location.href);
                const paramsSearchCurrentPage = urlSearchCurrentPage.searchParams;
                paramsSearchCurrentPage.set('q', String(inputValueToUpDate));
                urlSearchCurrentPage.search = paramsSearchCurrentPage.toString();

                window.history.pushState('object', document.title, urlSearchCurrentPage.toString());

                detectSearchResults(newSearchUrl, inputValueToUpDate, false, isAuthorMode);
            });

            $input.addEventListener('keypress', e => {
                if (e.key === 'Enter') {
                    const inputValueToUpDate = $input.value;
                    params_search.set('q', String(inputValueToUpDate));
                    urlSearch.search = params_search.toString();
                    newSearchUrl = urlSearch.toString();

                    const urlSearchCurrentPage = new URL(window.location.href);
                    const paramsSearchCurrentPage = urlSearchCurrentPage.searchParams;
                    paramsSearchCurrentPage.set('q', String(inputValueToUpDate));
                    urlSearchCurrentPage.search = paramsSearchCurrentPage.toString();
                    
                    window.history.pushState('object', document.title, urlSearchCurrentPage.toString());

                    detectSearchResults(newSearchUrl, inputValueToUpDate, false, isAuthorMode);
                }
            });
        }
    }

    searchInput();
}
