import "./carousel.scss";
import {carousels, getTargets, groupArray} from "../../ts/utilities";

export default function Carousel() {

    const $targets = getTargets('carousel');

    function detectCarousels(swiperWrapper: any, imagesSlide: any, size: number) {
        const $swiperWrapper = swiperWrapper.querySelector('.swiper-wrapper') as HTMLDivElement;
        const swiperChilds = $swiperWrapper?.querySelectorAll('.swiper-slide') as any;

        if (swiperChilds && swiperChilds?.length > 0) {
            swiperChilds.forEach((child: any) => {
                child.remove()
            })
        }

        const slidesToMap = groupArray({arr: imagesSlide, size: size});
        slidesToMap.map((images: any) => {
            const swiperSlide = document.createElement('div');
            swiperSlide.classList.add('swiper-slide');

            images.forEach((attributes: any) => {
                const img = document.createElement('img');
                Object.keys(attributes).forEach(attribute => {
                    img.setAttribute(attribute, attributes[attribute]);
                });
                swiperSlide.appendChild(img)
                swiperSlide.classList.add(`slides-${images.length}`)
            })

            $swiperWrapper.appendChild(swiperSlide)
        })

    }

    if ($targets.length > 0) {
        $targets.forEach((target : HTMLElement) => {



            const layoutChangedCallback = (matches: any) => {

                if (matches) {

                    const attribute = target.getAttribute('data-carousel-items') as string;

                    if (attribute) {
                        const imagesSlide = JSON.parse(attribute);

                        detectCarousels(target, imagesSlide, 1);

                        carousels({
                            targets: $targets,
                            id: 'carousel',
                            spaceBetween: 0,
                            dynamicBullets: false,
                            slidesMove: 1,
                            slidesToShow: 1
                        });

                        const activeSlideMobile = target.querySelector('.swiper-pagination-bullet') as HTMLElement;
                        activeSlideMobile.classList.remove('swiper-pagination-bullet-active');
                        setTimeout( function() {
                            activeSlideMobile.classList.add('swiper-pagination-bullet-active');
                        }, 1);
                    }
                } else {

                    const attribute = target.getAttribute('data-carousel-items') as string;
                    if(attribute) {
                        const imagesSlide = JSON.parse(attribute);

                        detectCarousels(target, imagesSlide, 3);

                        carousels({
                            targets: $targets,
                            id: 'carousel',
                            spaceBetween: 0,
                            dynamicBullets: false,
                            slidesMove: 1,
                            slidesToShow: 1
                        });

                        const activeSlide = target.querySelector('.swiper-pagination-bullet') as HTMLElement;
                        activeSlide.classList.remove('swiper-pagination-bullet-active');
                        setTimeout( function() {
                            activeSlide.classList.add('swiper-pagination-bullet-active');
                        }, 1);

                    }
                }

            }

            const mediaQuery = window.matchMedia('(max-width: 576px)');

            mediaQuery.addEventListener('change', (e) => layoutChangedCallback(e.matches));

            layoutChangedCallback(mediaQuery.matches);
        })

    }


}