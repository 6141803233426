import "./article-list.scss"

import {carousels, getTargets} from "../../ts/utilities";

export default function ArticleList() {

    const $targets = getTargets('article-list-carousel');
    if($targets.length > 0) {
        $targets.forEach((target: HTMLElement) => {
            let slide = target.querySelectorAll('.swiper-slide') as NodeListOf<HTMLElement>;

            carousels(
                {
                    targets: $targets,
                    id: 'article-list-carousel',
                    spaceBetween: 32,
                    spaceBetweenTablet: 16,
                    spaceBetweenMobile: 16,
                    dynamicBullets: true,
                    slidesToShow: slide.length <= 4 ? 4 : 5,
                    slidesToShowTablet: 'auto',
                    slidesToShowMobile: 'auto',
                    slidesMove:1,
                    slidesMoveTablet:1,
                    slidesMoveMobile:1,
                }
            );
        })
    }

}