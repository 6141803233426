import './scss/main.scss';
import './fragments/components';
import {
    Header,
    BrandGrid,
    TilesGridCarousel,
    ArticleList,
    RelatedItem,
    History,
    HeroCarousel,
    Carousel,
    ProductTabs,
    ProductTabsContent,
    HeroBanner,
    StoreLocator,
    CustomSelectLanguage,
    FiltersCategory,
    SearchResults,
    Card,
    SideGridType,
    ProductDetail,
    SocialShare,
    FiltersRecipeSearch
    
} from "./fragments/components";

import {ParallaxAnimation} from "./ts/utilities";


window.addEventListener('DOMContentLoaded', ()=> {
    Header();
    ArticleList();
    TilesGridCarousel();
    BrandGrid();
    RelatedItem();
    History();
    HeroCarousel();
    Carousel();
    StoreLocator();
    ProductTabs();
    ProductTabsContent()
    HeroBanner();
    CustomSelectLanguage();
    FiltersCategory();
    ParallaxAnimation();
    SearchResults();
    SideGridType();
    Card();
    FiltersRecipeSearch();
    ProductDetail();
    SocialShare();

    document.body.classList.add('load');

    setTimeout(function () {
        document.body.classList.remove('load');
    }, 600);
})

console.clear();
console.log("%c*********************************************************************", "color: #555")
console.log(`🧁🍭\ %c${import.meta.env.VITE_APP_NAME} - %cv${import.meta.env.VITE_VERSION}`, "font-weight: bold","color: #ff00aa");
console.log("%c*********************************************************************\n\n\n", "color: #555")



