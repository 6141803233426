import './select.scss';
import customSelects from 'custom-select';
import { getTargets } from '../../../ts/utilities';

export function CustomSelectLanguage() {
    const $targets = getTargets('language-select');

    if ($targets.length > 0) {
        $targets.forEach((target: HTMLDivElement) => {
            if (target.querySelector('select')) {
                const selectCustomLanguages = customSelects(target.querySelector('select'));

                const select = selectCustomLanguages[0].select;
                const selectCustomCont = selectCustomLanguages[0].select.customSelect as any;
                const valueSelected = select.value;
                const selectContainer = selectCustomCont.container;
                const selectPanel = selectContainer.querySelector('.custom-select-opener');
                const options = select.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
                const optionsCustom = selectContainer.querySelectorAll('.custom-select-option') as NodeListOf<HTMLOptionElement>;
                const panel = target.querySelector('.custom-select-panel') as HTMLDivElement;
                const panelHeight = panel.clientHeight;
                target.style.setProperty('--panelHeight', `${panelHeight}px`);
                window.addEventListener('resize', () => {
                    const panel = target.querySelector('.custom-select-panel') as HTMLDivElement;
                    const panelHeight = panel.clientHeight;
                    target.style.setProperty('--panelHeight', `${panelHeight + 1}px`);
                });

                options.forEach((option: HTMLOptionElement) => {
                    const icn = option.getAttribute('icon-path');
                    const icon = document.createElement('div') as HTMLDivElement;
                    const attribute = option.getAttribute('data-light-mode');
                    icon.classList.add('icn');
                    icon.style.backgroundImage = `url("${icn}")`;

                    if (option.value === valueSelected) {
                        selectPanel.append(icon);
                    }

                    if (attribute === 'true' && option.value === valueSelected) {
                        selectPanel.classList.add('is-light-icon');
                    }

                });

                options.forEach((option: HTMLOptionElement, index) => {
                    const icn = option.getAttribute('icon-path');
                    const icon = document.createElement('div') as HTMLDivElement;
                    icon.classList.add('icn');
                    icon.style.backgroundImage = `url("${icn}")`;


                    optionsCustom.forEach((optionCustom, i) => {
                        if (i === index) {
                            optionCustom.append(icon);
                        }
                    });
                });

                select.addEventListener('change', () => {
                    const currentValue = select.value;
                    window.location.href = currentValue;
                });

                target.addEventListener('mouseleave', (e) => {
                    e.preventDefault();
                    const container = target.querySelector('.custom-select-container') as HTMLDivElement;
                    if(container.classList.contains('is-open')) {
                        const selectOpener = target.querySelector('.custom-select-opener') as HTMLDivElement;
                        selectOpener.click();
                    }

                });
            }
        });
    }
}
